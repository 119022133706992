import { CButton, CCard, CCardBody, CCardFooter, CCardHeader, CCardText, CCardTitle, CImage } from '@coreui/react'
import React from 'react'
import style from '../css/BetArea.module.css'
function PartyPick({ partyName, rate, bettings, onClick }) {
    return (
        <CCard className={style.partyPicker} onClick={onClick}>
            <CCardHeader className='px-0 pb-0 pt-0' style={{fontWeight: 'normal'}}>
                - gram
            </CCardHeader>
            <CCardBody className='pb-0 pt-1'>

                <CCardTitle style={{ fontWeight: 'bold' }}>
                    {bettings}
                    <br />
                    <CButton className={`d-none px-0 pt-0 pb-1 d-md-block ${style.betButton}`} onClick={onClick}>
                        <CImage src={`/chips/chip-betting-${partyName}.png`} style={{ width: '30%' }} />
                    </CButton>
                </CCardTitle>
            </CCardBody>
            <CCardFooter className={`px-0 pb-0 pt-0 fw-bold ${style[`picker${partyName}`]}`} >
                {partyName.toUpperCase()}<br />
                {partyName == 'DRAW' ? '1:' + rate : rate}
            </CCardFooter>
        </CCard>
    )
}

export default PartyPick
