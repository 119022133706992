import "@coreui/coreui/dist/css/coreui.min.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import { useEffect, useState } from 'react';
import 'react-nestable/dist/styles/index.css';
import "video-react/dist/video-react.css";
import { Navigate, Route, Routes, useParams, useSearchParams } from 'react-router-dom';
import csrfToken from './api/csrfToken';
import AuthProvider, { useAuth } from './features/Auth/context/AuthContext';
import { mainRoutes } from './routes';

function App() {
  document.title = "Hall68 Gaming Portal"
  const auth=useAuth()
  const [params] = useSearchParams()
  useEffect(()=>{
    csrfToken.get();
    if(params.get('_token')){
      auth.loginWithToken(params.get('_token'), function(){
      })

    }
  },[])
  return (
      <Routes>
        {mainRoutes.map((value, key)=>{
          return (<Route key={key} path={value.path} Component={value.Component} />)
        })}
      </Routes>
      
    
  );
}

export default App;
