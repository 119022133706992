import React from 'react';
import { useSpring, animated } from '@react-spring/web';

const RollNumber = ({ number }) => {
  // Sử dụng hook useSpring để tạo hiệu ứng số tăng dần đến giá trị mới
  
  const { num } = useSpring({
    from: { num: 0 },
    num: number,
    delay: 100,
    config: { duration: 1000 },
  });
  return (
    <div style={{ fontSize: '5em', fontWeight: 'bold', border: '2px solid rgb(171 149 189)', padding: '10px 20px', minWidth: '92px', textAlign:'center' }}>
      {/* Dùng animated.span để hiển thị giá trị động */}
      {<animated.span>{num.to(n => n.toFixed(0))}</animated.span>}
    </div>
  );
};

export default RollNumber;