import { CButton, CNav, CNavItem, CNavLink, CTabContent, CTable, CTableBody, CTableHead, CTableHeaderCell, CTableRow, CTabPane } from '@coreui/react';
import React, { useState } from 'react'
import style from "../css/BetArea.module.css";
import BetArea from './BetArea';
import { useAuth } from '../../../../Auth/context/AuthContext';
import { useLoginModalShow } from '../../../../../store';
import CurrentBet from './CurrentBet';
import TodayReport from './TodayReport';
import History from './History';
function BetAreaTabs({ hallId, fightId, fightNumber, rateWala, rateMeron }) {
    const [activeKey, setActiveKey] = useState(1);
    const { user } = useAuth()
    const { setLoginModalShow } = useLoginModalShow()
    return (
        <>
        <CNav
                variant="tabs"
                role="tablist"
            >
                <CNavItem>
                    <CNavLink
                        href="#!"
                        active={activeKey === 1}
                        onClick={() => setActiveKey(1)}
                        className={`${style.userReportPicker} ${activeKey === 1 ? style.userReportPickerActive : ""}`}
                    >
                        BET
                    </CNavLink>
                </CNavItem>
                <CNavItem>
                    <CNavLink
                        href="#!"
                        active={activeKey === 2}
                        onClick={() => setActiveKey(2)}
                        className={`${style.userReportPicker} ${activeKey === 2 ? style.userReportPickerActive : ""}`}
                    >
                        Current Bets
                    </CNavLink>
                </CNavItem>
                <CNavItem>
                    <CNavLink
                        href="#!"
                        active={activeKey === 3}
                        onClick={() => setActiveKey(3)}
                        className={`${style.userReportPicker} ${activeKey === 3 ? style.userReportPickerActive : ""
                            }`}
                    >
                        Today Report
                    </CNavLink>
                </CNavItem>
                <CNavItem>
                    <CNavLink
                        href="#!"
                        active={activeKey === 4}
                        onClick={() => setActiveKey(4)}
                        className={`${style.userReportPicker} ${activeKey === 4 ? style.userReportPickerActive : ""
                            }`}
                    >
                        Law
                    </CNavLink>
                </CNavItem>
            </CNav>
        {user != null ? <BetArea hallId={hallId} fightId={fightId} fightNumber={fightNumber} rateWala={rateWala} rateMeron={rateMeron} /> : <CButton color='light' shape='rounded-pill' onClick={() => setLoginModalShow(true)} style={{ marginTop: '30%', width: '100%' }}>Login / Register to start betting</CButton>}
            
            <CTabContent className='tab-bets' style={{ background: '#0a4080' }}>
                
                <CTabPane
                    role="tabpanel"
                    aria-labelledby="home-tab"
                    visible={activeKey === 1}
                >
                <History hallId={hallId} />
                    
                </CTabPane>
                <CTabPane
                    role="tabpanel"
                    aria-labelledby="home-tab"
                    visible={activeKey === 2}
                    style={{ overflowY: "auto" }}
                >
                    <CurrentBet fightId={fightId} fightNumber={fightNumber} />
                </CTabPane>
                <CTabPane
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                    visible={activeKey === 3}
                    style={{ overflowY: 'scroll' }}
                >
                    <TodayReport />
                </CTabPane>
                <CTabPane
                    role="tabpanel"
                    aria-labelledby="law-tab"
                    visible={activeKey === 4}
                    style={{ backgroundColor: '#fff', overflowY: 'scroll', fontFamily: 'math', maxHeight: '184px' }}
                    className="pt-3"
                >
                    <ul>
                        <li><b>1: </b>A chicken must stand up 3 times and lie down 3 times (if the chicken lies down but still fights, it is considered as lying down).</li>
                        <li><b>2: </b>If a chicken flees during a fight, but does not resist or bite, it should be released back into the ring. If it resists, it should continue. If it doesn't resist, it is considered a loss.</li>
                        <li><b>3: </b>If the chicken is hit in the beak or falls out of the ring, it should be returned to the arena. If it does not fight, it will be considered a loss.</li>
                        <li><b>4: </b>If a chicken runs away or screams during a fight, it is considered an immediate loss.</li>
                        <li><b>5: </b>If a chicken leaves the ring 3 times, it will be considered a loss. The other chicken must stay in position to continue the fight. If one dies and the other runs away, it will be a draw.</li>
                        <li><b>6: </b>If a chicken stands with its head drooping and does not attempt to fight, it will be considered as not resisting. After 3 such instances, the chicken will be considered a loss.</li>
                        <li><b>7: </b>Both chickens must follow the referee’s instructions. If one chicken wins or is exhausted, both legs are considered finished. If anyone violates the rules, the referee will act according to the law.</li>
                        <li><b>8: </b>If a chicken is nearing exhaustion and violates the rules 3 times, it will be considered a loss. If it wins after violations, the fight will be considered a draw.</li>
                        <li><b>9: </b>No changing the mouth (beak), no canceling the chicken, and no pairing chickens under any circumstances. Violations will result in the chicken being seized and punished.</li>
                        <li><b>10: </b>If a chicken falls or dies, it loses the right to be handled. The result depends on luck and the referee’s decision. If there is non-compliance with instructions, the chicken will be considered an immediate loss.</li>
                    </ul>
                </CTabPane>
            </CTabContent>
        </>
    )
}

export default BetAreaTabs
