import { cilCheck, cilX } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CButton, CButtonGroup, CCol, CModal, CModalBody, CModalHeader, CModalTitle, CRow } from "@coreui/react";
import React, { useState } from "react";
import { currentBetValue, useUserChips } from "../../../../../store";
import style from "../css/BetArea.module.css";
import ChipSettings from "./ChipSettings";
import Chips from "./Chips";
import PartyPick from "./PartyPick";
import betApi from "../../../api/betApi";
import { toast } from "react-hot-toast";
import History from "./History";
function BetArea({ hallId, fightId, fightNumber, rateWala, rateMeron }) {
  const { userChips } = useUserChips();
  const [betSide, setBetSide] = useState(null);
  const [betValue, setBetValue] = useState(0);
  const [showSettings, setShowSettings] = useState(false);
  const { currentValue, setCurrentValue } = currentBetValue();
  const addBetValue = (side, value) => {
    if (side !== betSide) {
      setBetSide(side);
      setBetValue(value);
    } else {
      setBetValue(betValue + value);
    }
  };
  const confirmBet = async () => {
    const payload = {
      party: betSide,
      amount: betValue,
      fightId: fightId,
    };
    
    console.log(payload)
    try {
      const bet = await betApi.placeBet(payload)
      toast.success("Place bet successful!")
      let currentBet = localStorage.getItem('betting-'+fightId)
      if(localStorage.getItem('betting-'+fightId) == null){
        localStorage.setItem('betting-'+fightId, JSON.stringify([bet]))
        console.log('tao moi')
      }
      else{
        currentBet = JSON.parse(currentBet)
        currentBet.push(bet)
        localStorage.setItem('betting-'+fightId, JSON.stringify(currentBet))
        console.log('them')
      }
      window.dispatchEvent(new Event('bettingChange'))
      console.log(localStorage.getItem('betting-'+fightId))
    } catch (error) {
    }
  };
  const cancelBet = () => {
    setBetSide(null);
    setBetValue(0);
  };
  return (
    <div className={style.betArea}>
      <CRow className="mx-0 mt-2">
      <CCol
          xs={6}
          className="px-1"
        >
          <PartyPick
            onClick={() => addBetValue("meron", currentValue)}
            bettings={betSide === "meron" ? betValue : "-"}
            partyName="meron"
            rate={rateMeron}
          />
        </CCol>
        <CCol
          xs={6}
          className="px-1"
        >
          <PartyPick
            onClick={() => addBetValue("wala", currentValue)}
            bettings={betSide === "wala" ? betValue : "-"}
            partyName="wala"
            rate={rateWala}
          />
        </CCol>
        
      </CRow>
      <CRow className="pt-2 mx-0">
        {userChips.map((value, key) => {
          return (
            <CCol
              className="px-2"
              style={{ width: "8%" }}
              key={key}
            >
              <Chips
                value={value}
                state={currentValue === value ? "betValueActive" : ""}
                onClick={() => setCurrentValue(value)}
              />
            </CCol>
          );
        })}
        <CCol
          className="px-2"
          style={{ width: "8%" }}
        >
          <img
            alt="chipsSetting"
            src="/chips/chip-setting-2.png"
            style={{ width: "100%" }}
            onClick={() => setShowSettings(!showSettings)}
          />
        </CCol>
      </CRow>

      <CRow className="mx-0 pt-2 pb-2">
        <CButtonGroup
          role="group"
          aria-label="action button"
        >
          <CButton
            color="secondary"
            onClick={() => cancelBet()}
          >
            <CIcon icon={cilX}></CIcon> Cancel
          </CButton>
          <CButton
            color="primary"
            onClick={() => confirmBet()}
          >
            <CIcon icon={cilCheck}></CIcon> Confirm
          </CButton>
        </CButtonGroup>
      </CRow>
      <CRow className="mx-0 pt-2 pb-2">
      </CRow>
      <CModal
        visible={showSettings}
        onClose={() => setShowSettings(false)}
        transition={false}
        aria-labelledby="Chip Settings"
      >
        <CModalHeader className="title-center">
          <CModalTitle id="ChipSettingsModaLabel" style={{margin:'auto'}}>Pick your chips</CModalTitle>
        </CModalHeader>
        <CModalBody style={{background:'#184e82'}}>
        <CRow className={`pt-4 mx-3 mt-4 px-0 pb-3 ${style.chipSettings}`}>
          <ChipSettings setShowSetting={setShowSettings} />
        </CRow>
        </CModalBody>
      </CModal>
    </div>
  );
}

export default BetArea;
