import { useState, useEffect } from 'react';
import { ref, onValue, set, push } from 'firebase/database';
import { database } from './firebase'; // Đường dẫn đến file cấu hình Firebase của bạn

// Custom hook để lắng nghe và gửi tin nhắn
const useJackpot = () => {
  const [jackpot, setJackpot] = useState(0);

  // Lắng nghe dữ liệu tin nhắn theo thời gian thực
  useEffect(() => {
    const messagesRef = ref(database, `store/jackpot`);

    const unsubscribe = onValue(messagesRef, (snapshot) => {
      const data = snapshot.val();
      setJackpot(data);
    });

    return () => unsubscribe(); // Cleanup khi component unmount
  }, []);


  return { jackpot };
};

export default useJackpot;