import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from '@coreui/react'
import React from 'react'

function VideoModal({videoOpen, setVideoOpen, videoSrc}) {
    console.log(videoOpen)
    return (
        <CModal
      visible={videoOpen}
      onClose={() => setVideoOpen(false)}
      size='lg'
    >
      <CModalBody>
      <video autoPlay controls muted style={{margin: 'auto', width: '100%', border:'0px solid #fff'}}>
                <source src={videoSrc} type="video/mp4" />
                </video>
      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" onClick={() => setVideoOpen(false)}>
          Close
        </CButton>
      </CModalFooter>
    </CModal>
    )
}

export default VideoModal
