import React from 'react'
import RollNumber from '../../../components/RollNumber'
import useJackpot from '../../../hooks/useJackpot';

function Jackpot() {
    const { jackpot } = useJackpot();
    return (
        <div style={style}>
            {
                Number(jackpot).toLocaleString('vi-VN').split('').map((item, index) => {
                    if(item=='.') return <div key={index} style={{fontSize: '5em', fontWeight: 'bold', border: '2px solid rgb(171 149 189)', padding: '10px 20px', minWidth: '92px', textAlign: 'center'}}>.</div>
                    return <RollNumber key={index} number={parseInt(item)} />
                }
                )
            }
        </div>
    )
}
const style = {
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center', 
    height: '100vh',
    backgroundColor: 'black',
    color: 'white',
}
export default Jackpot
