import {
  CCard,
  CCardBody,
  CCardFooter,
  CCardText,
  CCardTitle,
  CCol,
  CContainer,
  CHeader,
  CRow,
  CTable,
  CTableHeaderCell,
  CTableHead,
  CTableRow,
  CTableBody,
  CTableDataCell,
  CButton,
} from "@coreui/react";
import React, { useState } from "react";
import style from "./Hall/css/BetArea.module.css";
import CIcon from "@coreui/icons-react";
import { cilMediaPlay } from "@coreui/icons";
import VideoModal from "./VideoModal";
export default function VideoPlayback() {
  const [videoOpen, setVideoOpen] = useState(false)
  const [videoSrc, setVideoSrc] = useState('')
  const [fights, setFights] = useState([
    {
      date: "15/11/2024",
      video: "/Video.mp4",
      number: 5,
      result: "WALA"
    },
    {
      date: "15/11/2024",
      video: "/Video.mp4",
      number: 4,
      result: "WALA"
    },
    {
      date: "15/11/2024",
      video: "/Video.mp4",
      number: 3,
      result: "MERON"
    },
    {
      date: "15/11/2024",
      video: "/Video.mp4",
      number: 2,
      result: "MERON"
    },
    {
      date: "15/11/2024",
      video: "/Video.mp4",
      number: 1,
      result: "WALA"
    }
  ])
  const playVideo = (video) => {
    console.log(video)
    setVideoSrc(video)
    setVideoOpen(true)
  }
  return (
    <CContainer
      xl
      className={style.hallContainer}
      style={{ height: "calc(100vh - 65px);" }}
    ><CHeader style={{ background: 'black', color: 'white' }}><h5>Playback Videos</h5></CHeader>
      <CRow>
        <CCol xl="12">

        </CCol>
        <CTable>
          <CTableHead>
            <CTableRow>
              <CTableHeaderCell>Date</CTableHeaderCell>
              <CTableHeaderCell>Fight No.</CTableHeaderCell>
              <CTableHeaderCell>Result</CTableHeaderCell>
              <CTableHeaderCell>Play</CTableHeaderCell>
            </CTableRow>
          </CTableHead>
          <CTableBody>
            {
              fights.map((fight, index) => (
                <CTableRow key={index}>
                  <CTableDataCell>{fight.date}</CTableDataCell>
                  <CTableDataCell>#{fight.number}</CTableDataCell>
                  <CTableDataCell>{fight.result}</CTableDataCell>
                  <CTableDataCell>
                    <CButton size="sm" color="primary" onClick={()=>playVideo(fight.video)}>
                      <CIcon icon={cilMediaPlay} />
                    </CButton>
                  </CTableDataCell>
                </CTableRow>
              ))
            }
          </CTableBody>
        </CTable>
<VideoModal videoOpen={videoOpen} setVideoOpen={setVideoOpen} videoSrc={videoSrc} />
      </CRow>
    </CContainer>
  );
}
