import { CCol, CContainer, CImage, CRow } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import { useLoginModalShow } from '../../../../store'
import { useAuth } from '../../../Auth/context/AuthContext'
import hallApi from '../../api/hallApi'
import { useSocket } from '../../hooks/socketHook'
import style from '../Hall/css/BetArea.module.css'
import BetAreaTabs from './elements/BetAreaTabs'
import DevTools from './elements/ChatBox'
import HallPicker from './elements/HallPicker'
import History from './elements/History'
import CIcon from '@coreui/icons-react'
import { cilVolumeHigh } from '@coreui/icons'

function SingleHall({ hall }) {
    const hallId = hall.id
    const videoSource = hall.videoSource
    const { user } = useAuth()
    const { setLoginModalShow } = useLoginModalShow()
    const [data, setData] = useState({})
    const [liveStatus, setLiveStatus] = useState(false)
    const [rateWala, setRateWala] = useState(0)
    const [rateMeron, setRateMeron] = useState(0)
    const [betStatus, setBetStatus] = useState(0)
    const [fightId, setFightId] = useState(null)
    const [fightNumber, setFightNumber] = useState(null)
    const [result, setResult] = useState(null)
    const [end_bet_at, setEnd_bet_at] = useState(null)
    const [elapsedTime, setElapsedTime] = useState(0)
    const [startedAt, setStartedAt] = useState(null)
    const [intervalId, setIntervalId] = useState(null)
    const [fightStart, setFightStart] = useState(null)
    const [showResultOverlay, setShowResultOverlay] = useState(false)
    useEffect(() => {
        const fetchResult = async () => {
            try {
                const datalastfight = await hallApi.lastFight(hallId);
                setResult(datalastfight.result);
                setEnd_bet_at(datalastfight.end_bet_at);
                if (datalastfight.started_at) {
                    setStartedAt(new Date(datalastfight.started_at));
                }
            } catch (error) {
                console.error(error);
            }
        };

        if (hallId) {
            fetchResult();
        }
    }, [hallId]);

    useSocket({
        type: 'RATE_CHANGE',
        callBack: (payload) => {
            switch (payload.party) {
                case 'wala':
                    setRateWala(payload.rate)
                    setRateMeron(payload.rate2)
                    break
                case 'meron':
                    setRateMeron(payload.rate)
                    setRateWala(payload.rate2)
                    break
                default:
                    break
            }
        }
    })

    useSocket({
        type: 'STATUS_CHANGE',
        callBack: (payload) => {
            console.log('payload', payload)
            setBetStatus(payload.status);
            switch (payload.status) {
                case 1:
                    const data = payload.data.fight;
                    updateFightData({
                        rateWala: data.rateWala,
                        rateMeron: data.rateMeron,
                        status: data.status,
                        currentFight: data.id,
                        currentFightNumber: data.fight_number,
                        startedAt: data.started_at
                    });
                    setResult(null);
                    setEnd_bet_at(null);
                    setShowResultOverlay(false);
                    break;
                case 2:
                    console.log(payload.data)
                    setResult(payload.data.result);
                    setFightStart(payload.data.fight_start);
                    setEnd_bet_at(payload.data.end_bet_at);
                    setShowResultOverlay(false);
                    break;
                case 0:
                    setResult(payload.data.result);                    
                    setBetStatus(payload.data.status);
                    setEnd_bet_at(payload.data.end_bet_at);
                    setShowResultOverlay(true);
                    break;
                default:
                    break;
            }
        }
    });

    useEffect(() => {
        fetchData(hallId)
    }, [hallId])

    const fetchData = async (hallId) => {
        if (hallId !== undefined) {
            try {
                const data = await hallApi.detail(hallId)
                setLiveStatus(data.hall.live === 1)
                updateFightData(data)
                return data
            } catch (error) {
                console.error('Failed to fetch hall detail:', error)
            }
        }
    }

    const updateFightData = (data) => {
        console.log('data: ',data)
        setRateMeron(data.rateMeron)
        setRateWala(data.rateWala)
        setBetStatus(data.status)
        console.log('data.status: ', data.status)
        setFightId(data.currentFight)
        setFightNumber(data.currentFightNumber)
        if (data.started_at) {
            setStartedAt(new Date(data.started_at))
        }
        if(data.status == 2){
            setFightStart(data.fight_start)
        }
    }

    useEffect(() => {
        let interval = null;
    
        if (betStatus==2) {
            console.log('fightStart: ', fightStart)
            console.log('current: ', Date.now())
                interval = setInterval(() => {
                    const now = Date.now();
                    const elapsed = now - Number(fightStart)*1000;
                    setElapsedTime(elapsed);
                }, 10);
        } else {
            clearInterval(intervalId);
            setElapsedTime(0);
        }
        return () => clearInterval(interval);
    }, [result, betStatus, fightStart]);

    const formatTime = (milliseconds) => {
        const totalSeconds = Math.floor(milliseconds / 1000)
        const minutes = Math.floor(totalSeconds / 60).toString().padStart(2, '0')
        const seconds = (totalSeconds % 60).toString().padStart(2, '0')
        const millis = (milliseconds % 1000).toString().padStart(3, '0')
        return `${minutes}:${seconds}:${millis}`
    }

    const fightStatusText = () => {
        if (betStatus == 1) {
            return (
                <div className="fightNumOverlay">
                    <div className="fightNumber">
                        Betting
                    </div>
                </div>
            );
        } else if(betStatus == 2) {
            return (
                <div className="fightNumOverlay">
                    <div className="fightNumber">
                        Fighting ({formatTime(elapsedTime)})
                    </div>
                </div>
            );
        }
        else if (result === 'cancel') {
            return (<div className="fightNumOverlay">
                <div className="fightNumber">
                    Match canceled
                </div>
            </div>);
        } else if (result !== null && betStatus === 0) {
            return (
                <div className="fightNumOverlay">
                    <div className="fightNumber">
                    ({result.toUpperCase()} WIN)
                    </div>
                </div>
            );
        } else {
            return (
                <div className="fightNumOverlay">
                    <div className="fightNumber">
                    Over
                    </div>
                </div>
            );
        }
    }
    
    if (!hallId) {
        return <>Loading...</>
    }

    return (
        <CContainer xl className={style.hallContainer}>
            <CRow>
                <marquee style={{color: 'white',borderBottom: '1px solid #ffffff50'}}>
                    <CIcon icon={cilVolumeHigh}/> Thông báo: Trận đấu số #15 bị huỷ do gà phát sinh vấn đề
                </marquee>
            </CRow>
            <CRow>
            <CCol sm={12} lg={8} className='px-0'>

            <HallPicker hallId={hall.key} type={hall.type}/>
                {/* <HallPlayer source={videoSource} height='480' />: */}
                <div className="content">
                    <div className="overlay">
                        {fightStatusText()} 
                    </div>
                    <div className={`resultOverlay ${betStatus==0 && result?'active '+result:''}`}>
                        <p className='resultOverlayText'>{result&&result.toUpperCase()}</p>
                        <p className='resultOverlaySubText'>WIN</p>
                        <p className='jackpotTitleOverlay'>JACKPOT</p>
                        <p className='jackpotValueOverlay'>30.570.000</p>
                    </div>
                    <div className='fightStatusOverlay'>
                        {/* <CImage src='/new_theme/live.png' style={{verticalAlign: 'top', maxWidth: '66%'}} /> */}
                        #{fightNumber}
                    </div>
                <video autoPlay controls muted style={{margin: 'auto', width: '100%', border:'0px solid #fff'}}>
                <source src="/Video.mp4" type="video/mp4" />
                </video>
                </div>
                {/* <Party /> */}
            </CCol>
            <CCol sm={12} lg={4} className='px-0'>
                <BetAreaTabs hallId={hallId} fightId={fightId} fightNumber={fightNumber} rateWala={rateWala} rateMeron={rateMeron} />
                {/* {user!=null?<BetArea hallId={hallId} fightId={fightId} fightNumber={fightNumber} rateWala={rateWala} rateMeron={rateMeron} />:<CButton color='light' shape='rounded-pill' onClick={()=>setLoginModalShow(true)} style={{marginTop: '30%', width: '100%'}}>Login / Register to start betting</CButton>} */}
                
            </CCol>
            </CRow>
            <CRow>
            {/* <CCol sm={12} lg={8} className='px-0'>
                    <History hallId={hallId} />
                </CCol> */}
                {user!=null?<><CCol sm={12} lg={4} className='px-0'>
                    {/* <UserReport fightId={fightId} fightNumber={fightNumber} /> */}
                </CCol></>:''}
            </CRow>
            <DevTools />
            {/* <JackpotBubble/> */}
        </CContainer>
    )
}

export default SingleHall
