import {
    CButton,
  CCard,
  CCardBody,
  CCol,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableRow,
} from "@coreui/react";
import { cilMediaPlay, cilSend } from "@coreui/icons";
import React, { useEffect, useState } from "react";
import hallApi from "../../../api/hallApi";
import dot from "../css/Dot.module.css";
import HistoryCircle from "./historyCircle";
import CIcon from "@coreui/icons-react";
import { Link } from "react-router-dom";

function History({ hallId }) {
  const [results, setResults] = useState([]);

  useEffect(() => {
    const fetchResult = async () => {
      try {
        const data = await hallApi.results(hallId);
        setResults(data);
      } catch (error) {}
    };
    fetchResult();
  }, [hallId]);
  const rows = 5;
  const cols = 20;
  const resultFormated = Array.from({ length: cols }, () =>
    Array(rows).fill(0)
  );

  if (results.length > 0) {
    let currentRow = 0;
    let currentCol = cols - 1;
    let previousResult = null;

    for (let m = results.length - 1; m >= 0; m--) {
      if (currentCol < 0) break;

      if (results[m] === previousResult) {
        if (currentRow < rows) {
          resultFormated[currentCol][currentRow] = results[m];
          currentRow++;
        }
      } else {
        if (currentRow > 0 || currentCol === cols - 1) {
          currentCol--;
          if (currentCol < 0) break;
          currentRow = 0;
        }
        resultFormated[currentCol][currentRow] = results[m];
        currentRow++;
      }
      previousResult = results[m];
    }
  }

  const finalResult = Array.from({ length: rows }, (_, rowIndex) =>
    resultFormated.map((col) => col[rowIndex] || 0)
  );

  const count = results.reduce((acc, result) => {
    acc[result] = (acc[result] || 0) + 1;
    return acc;
  }, {});

  return (
    <CCard style={{ borderRadius: 0 }}>
      <CCardBody>
        <CRow>
          {/* <CCol sm={1} style={{ width: "10%" }}>
            <span className="me-4">
              <span className={`${dot.dot} ${dot.meron} mb-2 mt-4`}>
                {count["meron"] || 0}
              </span>
            </span>
            <span className="me-4">
              <span className={`${dot.dot} ${dot.wala} mb-2`}>
                {count["wala"] || 0}
              </span>
            </span>
            <span className="me-4">
              <span className={`${dot.dot} ${dot.draw} mb-2`}>
                {count["draw"] || 0}
              </span>
            </span>
            <span className="me-4">
              <span className={`${dot.dot} ${dot.cancel} mb-2`}>
                {count["cancel"] || 0}
              </span>
            </span>
          </CCol> */}
          <CCol sm={12} >
            <CRow className="mb-2">
              <CCol xs={4}>
              <Link to={`/game/${hallId}/play`}>
              <CButton color="primary" size="sm">
                <CIcon icon={cilMediaPlay} /> Playback
              </CButton>
              </Link>
              </CCol>
            <CCol xs={2}>
              <span className={`${dot.dot} ${dot.meron}`} style={{width: '25px', height:'25px',fontSize: '15px'}}>
                {count["meron"] || 0}
              </span>
            </CCol>
            <CCol xs={2}>
              <span className={`${dot.dot} ${dot.wala}`} style={{width: '25px', height:'25px',fontSize: '15px'}}>
                {count["wala"] || 0}
              </span>
            </CCol>
            {/* <CCol xs={2}>
              <span className={`${dot.dot} ${dot.draw}`}>
                {count["draw"] || 0}
              </span>
            </CCol> */}
            <CCol xs={2}>
              <span className={`${dot.dot} ${dot.cancel}`} style={{width: '25px', height:'25px',fontSize: '15px'}}>
                {count["cancel"] || 0}
              </span>
            </CCol>
            {/* <span className="me-4">
              <span className={`${dot.dot} ${dot.wala} mb-2`}>
                {count["wala"] || 0}
              </span>
            </span>
            <span className="me-4">
              <span className={`${dot.dot} ${dot.draw} mb-2`}>
                {count["draw"] || 0}
              </span>
            </span>
            <span className="me-4">
              <span className={`${dot.dot} ${dot.cancel} mb-2`}>
                {count["cancel"] || 0}
              </span>
            </span> */}
            </CRow>
          
              
            <CTable bordered responsive align="middle" className="mb-0">
              <CTableBody>
                {finalResult.map((row, rowIndex) => (
                  <CTableRow key={rowIndex}>
                    {row.map((cell, cellIndex) => (
                      <CTableDataCell key={cellIndex} style={{padding: '0'}} className="historyCircle">
                        {cell !== 0 ? (
                          <HistoryCircle type={cell + "-sum"} />
                        ) : (
                          <HistoryCircle type={"sum"} />
                        )}
                      </CTableDataCell>
                    ))}
                  </CTableRow>
                ))}
              </CTableBody>
            </CTable>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  );
}

export default History;
