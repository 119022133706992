import { CBadge, CButton, CButtonGroup, CContainer, CHeader, CHeaderNav, CImage, CModal, CModalBody, CModalHeader, CModalTitle, CNavItem, CNavLink, CSidebarHeader } from '@coreui/react';
import { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { Outlet, useNavigate } from 'react-router-dom';
import LoginForm from '../../features/Auth/Login/LoginForm';
import { useAuth } from '../../features/Auth/context/AuthContext';
import { useLoginModalShow } from '../../store';
import CIcon from '@coreui/icons-react';
import { cilAccountLogout } from '@coreui/icons';
import profileApi from '../../features/User/api/profileApi';
export default function DashboardLayout() {
  const navigate = useNavigate();
  const { loginModalShow, setLoginModalShow } = useLoginModalShow();
  const auth = useAuth();
  const [balance, setBalance] = useState('0.00');  
  useEffect(() => {
    async function getBalance() {
      try {
        const response = await profileApi.balance();
        const balance = response.balance;
        setBalance(balance);
      } catch (error) {
        console.error("Lỗi khi lấy số dư:", error);
      }
    }

    const userLoggedIn = localStorage.getItem('user') !== null;
    if (userLoggedIn) {
      getBalance();
    }
  }, [localStorage.getItem('user')]);
  return (
    <div>

      <Toaster position='bottom-right' />

      <div className="wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent">
        <CHeader className="header">
          <CContainer className='px-0'>
            <CSidebarHeader>
              <CImage src="/imgs/newlogo.png" style={{ width: '124px' }} onClick={()=>navigate('/')}/>
            </CSidebarHeader>
            <CHeaderNav className="ms-3">

              <CNavItem className='ms-3'>
                {localStorage.getItem('user') != null ? (
                  <CButtonGroup role="group">
                    <CButton color='light'><CImage src='/chips/chip-betting.png' height={20} /> {parseFloat(balance).toLocaleString('vi-VN')}</CButton>
                    <CButton color='light' onClick={() => auth.logout()}><CIcon icon={cilAccountLogout} /> Logout</CButton>
                  </CButtonGroup>
                ) : (
                  <CButton color='light' shape='rounded-pill' onClick={() => setLoginModalShow(true)}>Login / Register</CButton>
                )}
              </CNavItem>

            </CHeaderNav>
          </CContainer>
        </CHeader>
        <div className="body flex-grow-1 px-0">
          <CContainer lg className='px-0'>
            <Outlet />
          </CContainer>
        </div>
      </div>
      <CModal
        backdrop="static"
        visible={loginModalShow}
        onClose={() => setLoginModalShow(false)}
        aria-labelledby="LoginModal"
      >
        <CModalHeader>
          <CModalTitle id="LoginModaLabel">Login to play game</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <LoginForm callback={() => { setLoginModalShow(false) }} />
        </CModalBody>
      </CModal>
    </div>
  );
}
